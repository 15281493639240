<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="dataInput.title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Upload Logo"
                  label-for="upload_logo"
                >
                  <b-form-file
                    ref="file-input"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="setFileImage"
                  />
                </b-form-group>
                <div
                  v-if="dataInput.logo"
                  style="position: relative"
                >
                  <b-img
                    :src="dataInput.logo"
                    rounded
                    alt="Rounded image"
                    class="d-inline-block mr-1 mb-1 w-100"
                  />
                  <div
                    style="position: absolute; top: 0.45rem; right: 0.5rem"
                    @click="resetImage"
                  >
                    <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <label for="description">Description</label>
                <b-form-textarea
                  id="description"
                  v-model="dataInput.description"
                  placeholder="Description"
                  rows="3"
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mt-1"
              >
                <b-form-group
                  label="Date"
                  label-size="sm"
                  label-for="filterDate"
                >
                  <b-input-group>
                    <flat-pickr
                      id="filterDate"
                      v-model="dataInput.date"
                      class="form-control"
                      :config="{ mode: 'range'}"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mt-1"
              >
                <b-form-group
                  label="Pin Code"
                  label-size="sm"
                >
                  <b-input-group class="mt-50">
                    <b-form-input
                      v-model="dataInput.pin_code"
                      placeholder="Pin Code"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="generateCode()"
                      >
                        Generate
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-button
                  variant="primary"
                  @click="submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody,
  BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BFormFile, BFormTextarea, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormFile,
    BFormTextarea,
    BButton,
    BImg,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataInput: {
        title: null,
        description: null,
        date: null,
        pin_code: null,
        file: {
          url: null,
          image: null,
        },
        file_raw: null,
        logo: null,
      },
      modal_crop_image: false,
    }
  },
  created() {
    this.checkAuth()
  },
  mounted() {
    this.getDetailPartner()
  },
  methods: {
    async getDetailPartner() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      const dataAPI = await this.$axios.get(`${cid}/partner/${id}`, config)
      const partner = dataAPI.data.data

      this.dataInput.title = partner.partner_name
      this.dataInput.description = partner.description
      this.dataInput.pin_code = partner.pin_code

      const startDate = partner.start_date
      const endDate = partner.end_date

      this.dataInput.date = `${startDate} to ${endDate}`
      this.dataInput.logo = partner.image
    },
    submit() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')
      const input = this.dataInput
      const data = new FormData()

      data.append('title', input.title)
      data.append('description', input.description)
      data.append('logo', input.file.image)

      const { date } = input
      const dateSplit = date.split(' to ')

      data.append('start_date', dateSplit[0])
      data.append('end_date', dateSplit[1])
      data.append('pin_code', input.pin_code)

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      this.$axios.post(`${cid}/partner/${id}?_method=PUT`, data, config)
        .then(() => {
          // Success code
          this.$bvToast.toast('Success', {
            title: 'Edit Partner',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 3000,
          })

          setTimeout(() => {
            this.$router.push({ path: '/partner' })
          }, 3000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    generateCode() {
      const result = Math.floor(Math.random() * (999999 - 100000)) + 100000
      this.dataInput.pin_code = result
    },
    setFileImage(event) {
      const file = event.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Please select an image file')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = eventReader => {
          const sizeFile = Math.floor(file.size / 1024)

          if (sizeFile <= 1024) {
            this.dataInput.file_raw = eventReader.target.result

            this.showModalCrop()

            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(eventReader.target.result)
          } else {
            this.$refs['file-input'].reset()
            this.runToast('error', 'Ukuran file lebih dari 1 MB')
          }
        }
        reader.readAsDataURL(file)
      } else {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Sorry, FileReader API not supported')
      }
    },
    resetImage() {
      this.$refs['file-input'].reset()

      this.dataInput.file.image = null
      this.dataInput.file.url = null
      this.dataInput.file_raw = null
      this.dataInput.logo = null
    },

    showModalCrop() {
      this.modal_crop_image = true
    },
    setCropArea() {
      this.$refs.cropper.setCropBoxData({
        width: 512,
        height: 512,
      })
    },
    resetCropImage() {
      if (!this.dataInput.file_raw) {
        this.$refs['file-input'].reset()
      }
    },
    handleCropImage() {
      this.dataInput.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()

      const block = this.dataInput.file.url.split(';')
      const contentType = block[0].split(':')[1]
      const realData = block[1].split(',')[1]
      this.dataInput.file.image = this.b64toBlob(realData, contentType)
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
